import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from './services/user.service';

@Injectable()
export class LoginRouteGuard implements CanActivate {

    constructor(private router: Router, private userService: UserService) { }

    canActivate() {

        let u = this.userService.getCurrentUser()

        if (u) {
            console.log('LoginRouteGuard ok')
            return true
        } else {
            console.log('LoginRouteGuard ko')
            this.router.navigate(['login'])
            return false
        }

    }


}

@Injectable()
export class NotLoginRouteGuard implements CanActivate {

    constructor(private router: Router, private userService: UserService) { }

    canActivate() {

        let u = this.userService.getCurrentUser()
        console.log(u)

        if (!u) {
            console.log('NotLoginRouteGuard ok')
            //this.router.navigate(['home'])
            console.log('NotLoginRouteGuard ko')
            return false
        } else {
            return true
        }

    }


}

@Injectable()
export class AdminRouteGuard implements CanActivate {

    constructor(private router: Router, private userService: UserService) { }

    canActivate() {

        if (this.userService.isAdmin()) {
            return true
        } else {
            this.router.navigate(['login'])
            return false
        }

    }


}


@Injectable()
export class PrivacyRouteGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate() {

        if (sessionStorage.getItem('privacy')) {
            return true;
        }

        this.router.navigate(['pages/join'])
        return false;

    }

}