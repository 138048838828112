import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { MysqlService } from './mysql.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  basename = 'authority'

  constructor() {

    let u = JSON.parse(localStorage.getItem(this.basename))
    console.log(u)
    if (u) this.setCurrentUser(u)

  }



  public user: any
  userState = []

  protected userState$ = new BehaviorSubject(this.userState[0]);

  setCurrentUser(user: any) {
    this.user = { ...user };
    console.log(user)
    localStorage.setItem(this.basename, JSON.stringify(user));
  }

  getCurrentUser() {

    let u = JSON.parse(localStorage.getItem(this.basename))
    console.log('getCurrentUser', u)
    return u
  }

  isAdmin(): boolean {
    if (!this.user) return false;
    return this.user.role == "ADMIN";
  }

  isBackoffice(): boolean {
    if (!this.user) return false;
    return this.user.role == "BACKOFFICE";
  }

  login(user) {
    this.setCurrentUser(user);
    this.setUserState(user);
  }

  logout() {
    this.user = {};
    let env = localStorage.getItem('ENVIRONMENTs')
    localStorage.clear();
    localStorage.setItem('ENVIRONMENTs', env)
    this.setUserState(this.user);

    window.location.assign("home")
  }

  setUserState(user: any): any {
    this.userState$.next(user);
  }

  getUserStates(): Observable<any[]> {
    return of(this.userState);
  }

  onUserState(): Observable<any> {
    return this.userState$.asObservable();
  }

  isLogged() {
    return this.user;
  }
}
