import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { getColor } from './shared';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen'

import { register } from 'swiper/element/bundle';
//import { SpinnerService } from './services/spinnerService.service';



import { ScreenOrientation } from '@capacitor/screen-orientation'

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styles: [` `]
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Nuovo incarico test',
      url: '/test',
      icon: 'logo-android'
    },
  /*  {
      title: 'Solera',
      url: '/solera',
      icon: 'home'
    },*/ {
      title: 'Storage',
      url: '/storage',
      icon: 'home'
    }, {
      title: 'Uploader',
      url: '/uploader',
      icon: 'home'
    },
    {
      title: 'Logout',
      url: '/login/out',
      icon: 'exit'
    }
  ];

  constructor(
    private platform: Platform,
    //public Service: SpinnerService
  ) {


    //alert('init')
    this.initializeApp();

    console.log('prova')



  }

  async showSplash() {

    if (this.platform.is('capacitor'))
      await StatusBar.setBackgroundColor({ color: '#639fb9' })

    // Hide the splash (you should do this on app launch)
    await SplashScreen.hide();


    // Show the splash for two seconds and then automatically hide it:
    /*
    await SplashScreen.show({
      showDuration: 2000,
      autoHide: true,
    });
    */
  }


  isLandscape = true

  color;

  lastOrientation
  initializeApp() {
    this.platform.ready().then(() => {
      // Display content under transparent status bar (Android only)
      //StatusBar.setOverlaysWebView({ overlay: true });

      AndroidFullScreen.isImmersiveModeSupported()
        .then(() => AndroidFullScreen.immersiveMode())
        .catch(console.warn);

      this.showSplash()

      if (this.platform.is('capacitor'))
        ScreenOrientation.lock({ orientation: 'landscape' })

      ScreenOrientation.addListener('screenOrientationChange', e => {
        //this.isLandscape =  e.type.startsWith('landscape')
        console.log(this.isLandscape)
      })


      this.color = getColor();


    });
  }
}


