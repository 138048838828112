<ion-card style="background-color: white; height:100%">

  <ion-card-header>
    <ion-card-subtitle>Seleziona un elemento</ion-card-subtitle>
    <ion-card-title>{{list[0]?.zona}}</ion-card-title>
  </ion-card-header>

  <ion-card-content>
    <div class="fixed">
      <ion-list>
        <ion-item *ngFor="let item of list" (click)="select(item)">
          {{item.elemento}}
        </ion-item>
      </ion-list>
    </div>
  </ion-card-content>
</ion-card>