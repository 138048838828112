import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-part-picker-modal',
  templateUrl: './part-picker-modal.component.html',
  styleUrls: ['./part-picker-modal.component.scss']
})
export class PartPickerModalComponent implements OnInit {

  list:any[] = ['']

  constructor(navParams: NavParams, private modalCtrl: ModalController) {
    // componentProps can also be accessed at construction time using NavParams
    this.list = navParams.get('list')
    console.log(this.list)
  }

  ngOnInit() {
  }

  select(item) {
    this.modalCtrl.dismiss(item);
  }

}
